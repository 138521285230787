import './styles.scss'
import React from 'react'

export default function Features() {
  return (
    <section id="features">
      <div className="container">

        <h6 className="section-heading">One Platform, All the Features</h6>
        <h1 className="section-title">
          Supercharge your dream Network & scale effortlessly on our Cloud Based Architecture
        </h1>

        <ul className="wrapper">
          <Feature caption='Pay to use Hotspot' />
          <Feature caption='M-PESA Integration (Paybill & Till)' />
          <Feature caption='Invoicing and Payments' />
          <Feature caption='Automatic connection & disconnection' />
          <Feature caption='Payment reminders' />
          <Feature caption='Online/Offline/Active/inactive customers' />
          <Feature caption='Compensate customers on outages.' />
          <Feature caption='Extend customer expiry at will' />
          <Feature caption='Get Unlimited Stations.' />
          <Feature caption='Export reports to excel list' />
          <Feature caption='Track & activate leads easily' />
          <Feature caption='Permission based dashboard' />
          <Feature caption='Ledger transactions' />
          <Feature caption='Expense & Bill tracking' />
          <Feature caption='Hotspot analytics' />
          <Feature caption='Unit Economics (Churn,LTV, etc)' />
        </ul>

      </div>
    </section>
  )
}

function Feature({ caption = '' }) {
  return (
    <li className='feature'>
      {caption}
    </li>
  )
}
