import './styles.scss'
import React, { useEffect } from 'react'
import { Button } from 'components'
import PricingPlans from 'utils/pricing'
import config from 'views/hero/particles.json'
import slugify from 'utils/slugify'
import icon from 'images/pricing.svg'

const data = PricingPlans.map(plan => {
  const { caption, rows } = plan;
  const featured = rows.find(x => x.featured) || rows[0];
  return { caption, featured, slug: slugify(caption) };
});

export default function Pricing() {

  useEffect(() => {
    config.particles.color.value = '#FFFFFF'
    loadParticles(config);
  }, []);

  return (
    <section id="pricing">
      <div className="container">

        <h6 className="section-heading">Always know what you’ll pay</h6>
        <h1 className="section-title">Pricing built for all businesses</h1>

        <div className="section-wrapper">

          {data.map(plan => <Plan key={plan.caption} content={plan} />)}

          <article className="pricing-plan flex">
            <div className="img-wrapper flex">
              <img src={icon} alt="pricing icon" />
            </div>
            <div className="rate-summary">
              View the complete list of pricing packages and pick one that best suits your business.
            </div>
            <div className="btn-wrapper">
              <Button url={`/pricing`}>View All</Button>
            </div>
          </article>
        </div>

      </div>
      <div id="pricing-particles-js" />
    </section>
  )
}

function Plan({ content }) {

  const { caption, slug, featured = 0 } = content;

  const summary = `Get the ${featured.plan} for  ${featured.pricing} `

  return (
    <article className="pricing-plan">

      <div className="plan-caption">
        <span>{caption}</span>
      </div>

      <div
        className="rate-summary"
        dangerouslySetInnerHTML={{ __html: summary }}
      />

      <div className="btn-wrapper">
        <Button url="https://stage.netpap.co.ke/auth/order">Order</Button>
      </div>

    </article>
  )
}

function loadParticles(jsonConfig) {
  const particles = window?.particlesJS;
  if (!window || !particles) return undefined;
  return particles('pricing-particles-js', jsonConfig);
}
