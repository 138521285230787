import './styles.scss'
import React from 'react'

const data = [
  {
    name: 'milelenet',
    location: 'Ruiru',
    color: '#48487F'
  },
  {
    name: 'delaware lion',
    location: 'Lamu',
  },
  {
    name: 'wifi yetu',
    location: 'Rongo',
    color: '#0d0c0d'
  },
  {
    name: 'dice wireless',
    location: 'Ruaka',
    color: '#404042'
  },
  {
    name: 'glue telecom',
    location: 'Kasarani',
  },
  {
    name: 'Masud wifi',
    location: 'Namanga',
  },
  {
    name: 'Okapi Green',
    location: 'Kakuma Refugee Camp',
    color: '#06693A'
  },
];

export default function Clients() {
  return (
    <section id="clients">
      <div className="container">

        <h6 className="section-heading">Who Is Using Us</h6>

        <h1 className="section-title">
          <span>Designed & Built for enterprise!</span>
          <br/>
          <span>Available for everyone</span>
        </h1>

        <div className="section-wrapper">
          {
            data.map(partner =>
              <span key={Math.random()} className='logo-mark partner' title={`Located In ${partner.location}`}>
                {`${partner.name}`.toUpperCase()}
              </span>
            )
          }
        </div>

      </div>
    </section>
  )
}
