import './styles.scss'
import React from 'react'
import slugify from 'utils/slugify'
import services from 'utils/services';

export default function About() {
  const data = services.filter(x => !x.hidden);
  return (
    <section id='about'>
      <div className="container">

        <h1 className="section-title">Why Netpap?</h1>

        <h6 className="section-heading">
          Limitless possibilities for Internet Service Providers.
        </h6>

        <div className="section-wrapper">
          {
            data.map(feature => (
              <article className='service' key={slugify(feature.title)}>
                <img src={feature.banner} alt={`${feature.title} banner`} />
                <div className="content-wrapper">
                  <h3 className='heading'>{feature.title}</h3>
                  <p className='description'>{feature.description}</p>
                </div>
              </article>
            ))
          }
        </div>

      </div>
    </section>
  )
}
