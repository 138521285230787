import React from "react"
import Hero from 'views/hero'
import Header from 'views/header'
import Footer from 'views/footer'
import AboutUs from 'views/about'
import Clients from 'views/clients'
import Pricing from 'views/pricing'
import Features from 'views/features'
import Partners from 'views/partners'
import Statistics from 'views/statistics'
import { Seo, Layout } from "components"
import Awards from "views/awards"

const HomePage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <Header />
      <Hero />
      <AboutUs />
      <Features />
      <Clients />
      <Pricing />
      <Partners />
      <Awards />
      <Statistics />
      <Footer />
    </Layout>
  )
}

export default HomePage;
