import './styles.scss'
import React from 'react'
import icon from 'images/award.svg'

export default function Awards() {
  return (
    <section id="awards">
      <div className="container">

        <div className="award flex">
          <h2 className="award-heading">international telecommunication union innovation nominee - 2019</h2>
          <p className="award-description">
            Its quest is to connect lives through promoting and facilitation of micro-grids and shared networks. Netpap was nominated to the ITU Durban innovation conference of 2019 through the ministry of ICT.
          </p>
        </div>

        <div className="award-image flex">
          <img src={icon} alt="award icon" />
          <h1 className="section-title">CELEBRATING 5+ YEARS OF EXCELLENCE</h1>
        </div>

        <div className="award flex">
          <h2 className="award-heading">Kenya innovation agency (Kenia) - 2018</h2>
          <p className="award-description">
            As a beneficiary of the agency Netpap was bestowed the prestigious award for its revolutionary idea on shared connectivity and its tools to facilitate internet penetration in the country.
          </p>
        </div>


      </div>
    </section>
  )
}
