import './styles.scss'
import React, { useEffect } from 'react'
import { Button } from 'components'
import graphic from 'images/hero.svg'
import config from './particles.json'

export default function Hero() {

  useEffect(() => {
    loadParticles();
  }, []);

  return (
    <section id="home">
      <div className="container">

        <div className="hero-body">

          <h1 className='hero-title'>
            <span>Cloud Based</span>
            <br/>
            <span>ISP Billing & Hotspot</span>
            <br/>
            <span>Management</span>
          </h1>

          <p className='hero-summary'>
          A carrier grade platform for serious internet providers.
          </p>

          <Button component="a" url="https://stage.netpap.co.ke/auth/order" className="hero-btn btn">
            Get Started Now!
          </Button>

        </div>

        <div className="hero-image">
          <img src={graphic} alt="header" />
        </div>

      </div>
      <div id="hero-particles-js"/>
    </section>
  )
}

function loadParticles() {
  const particles =  window?.particlesJS;
  if (!window || !particles) return undefined;
  return particles('hero-particles-js', config);
}
