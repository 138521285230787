import './styles.scss'
import React, { useEffect, useState } from 'react'
import { parseNumber } from 'utils';

export default function Statistics() {

  const [data, setData] = useState({
    users: 7024,
    fixed: 3014,
    hotspot: 3980,
    transactions: 27503167,
    vouchers: 43250,
  });

  useEffect(() => {
    async function fetchStats() {
      const url = `https://netpap.co.ke/api/public/landingstats/`;
      const res = await fetch(url).catch(e => ({ error: e.message }));
      const json = res.error ? res : await res.json();
      if (!json.error) setData(json);
    }
    fetchStats();
  }, []);

  return (
    <section id='statistics'>
      <div className="container">

        <h6 className="section-heading">Trust the Numbers</h6>
        <h1 className="section-title">Deliver what your customers want every time</h1>

        <div className="section-wrapper">

          <article className='stat'>
            <div className="value">{parseNumber(data.vouchers)}</div>
            <div className="label">Vouchers<br />Issued</div>
          </article>

          <article className='stat'>
            <div className="value">{parseNumber(data.fixed)}</div>
            <div className="label">Fixed Broadband<br />Users</div>
          </article>

          <article className='stat'>
            <div className="value">{parseNumber(data.hotspot)}</div>
            <div className="label">Hotspot Solution<br />Users</div>
          </article>

          <article className='stat'>
            <div className="value">{parseNumber(data.users)}</div>
            <div className="label">Total Netpap<br />Users</div>
          </article>

          <article className='stat'>
            <div className="value">{parseNumber(data.transactions)}</div>
            <div className="label">Total Transactions<br />(Ksh)</div>
          </article>

        </div>

      </div>
    </section>
  )
}
