import './styles.scss'
import React from 'react'

const data = [
  'crossgateway',
  'tillil tech',
  'Africastalking',
  'Extradimension',
  'equitel',
  'kopo kopo',
  'mobitech',
  'OpalQuick Limited',
  'Tiny Pesa',
  'profit well',
  'heap analytics',
  'sendgrid'
];

export default function Partners() {
  return (
    <section id="partners">
      <div className="container">

        <h6 className="section-heading">Our Gracious Partners</h6>

        <div className="section-wrapper">
          {
            data.map(partner =>
              <span key={Math.random()} className='logo-mark partner'>
                {`${partner}`.toUpperCase()}
              </span>
            )
          }
        </div>

      </div>
    </section>
  )
}
