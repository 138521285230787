import signal from 'images/network-bars.svg'
import server from 'images/server-rack.svg'
import router from 'images/wifi-router.svg'
import network from 'images/family-tree.svg'
import tunnels from 'images/internal-organ.svg'
import support from 'images/cog.svg'

const services = [
  {
    banner: network,
    url: '',
    title:  'PPPoE Management',
    description: 'Manage PPPOE  customers conveniently from one dashboard. Create customers, assign plans and dial client to enjoy internet.When an account expires,all connected customer devices are redirected to an expiry page.Think Customizable sms reminders,burst rates,data caps,FUP'
  },
  {
    banner: router,
    title:  'Hotspot Management',
    url: 'https://pin.it/61XZpbV',
    description: 'The fastest hotspot system on the planet!Designed and built to convert an hotspot visit to a repeated sale.No customer registration,the system just learns about customer with perpetual use.Customer process is soo fast,you will wonder if its real. '
  },
  {
    banner: server,
    title:  'DHCP/STATIC IP Monitoring',
    url: '',
    description: 'Assigning a customer a static ip and queue often times is the easiest thing to begin business with.As business grows, more ip addresses becomes more problems.It becomes paramount to automate your business processes.Netpap provides the technology to help you transition to automation without migrating or changing your customers.'+'<br>'+'.Assign an  account to customer,and netpap bonds with customer router/cpe for automation.That simple'
  },
  {
    banner: tunnels,
    title:  'Netpap Tunnels',
    url: '',
    description: 'Dont have a public IP?Are you burning fuel,electricity  to visit base station?Deploy a secure Netpap tunnel between your router and your laptop/phone for easy remote management.'
  },
  {
    banner: signal,
    title:  'Network Monitoring',
    url: '',
    description: 'Use our network monitoring software to track incidents, generate network weather maps & track resource & bandwidth utilization.',

  },
  {
    banner: support,
    title:  'CPE Auto Configuration Service',
    url: '',
    description: 'Achieve zero touch configuration,remote CPE provisioning,troubleshooting,connected devices,bandwidth consumption and  resource utilization based on the carrier protocol-TR-069',
   
  },
  {
    banner: support,
    title:  'Free Support',
    url: '',
    description: 'We offer free round the clock support or assistance, free upgrades',
    hidden: true
  }
]

export default services;
